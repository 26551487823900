import React from "react"
import Layout from "../../components/h/layout"

const HalloweenHPage = () => (
  <Layout>
    <h2>Uncle Warren and aunty Jeannie gave you this gift.</h2>
    <h2>You ride it like a car but don’t try to drift.</h2>
  </Layout>
)

export default HalloweenHPage
